import React, { useEffect, useState } from 'react'
import { Container, Col, Row, Form } from "react-bootstrap"
import Icon from "../../images/icons.svg"
import { useStaticQuery, graphql } from 'gatsby'
import axios from "axios"
import { Helmet } from 'react-helmet'
import { Base64 }  from '../../helpers/base64'

const Subscribe = () => {
    const footerQuery = useStaticQuery(graphql`
    {
      ss {
        readOneSiteConfig {
          newsletterImage {
            absoluteLink
          }
        }
      }
    }
  `)
    const [siteConfig, setSiteConfig] = useState(null)
    useEffect(() => {
        setSiteConfig(footerQuery.ss.readOneSiteConfig)        
    })
    var inlineStyleBgImage = {}
    if (siteConfig && siteConfig.newsletterImage) {
      inlineStyleBgImage.backgroundImage = 'url(' + siteConfig.newsletterImage.absoluteLink + ')'
    }    
  return (
    <div
      className="subscribe"
      style={inlineStyleBgImage}
    >
      <Container>
        <Row className="justify-content-lg-end justify-content-center">
          <Col xs={12} sm={11} lg={8} xl={6} xxl={5}>
            <div className="subscribe-inner">
              <h2 className="h3">Join our mailing list</h2>
              <div id="newsletter-form">
                <p>Be the first to know about news, events, and progress.</p>
                <Form className="subscribe-form js-cm-form"
                    id="subForm" 
                    action="https://www.createsend.com/t/subscribeerror?description=" 
                    method="post" 
                    data-id="5B5E7037DA78A748374AD499497E309EA02923FCD67598C50461D2290D78CAA1FA198D395EEFE67BA4BA443F1795C7F65EAB3B932E19F1F483AA0F6F44B04774"                    
                    >
                  <div className="form-floating">
                    <input autoComplete="Email" 
                      aria-label="Email" 
                      className="form-control js-cm-email-input qa-input-email" 
                      id="fieldEmail" 
                      maxLength="200" 
                      name="cm-biiddlu-biiddlu" 
                      required="required" 
                      type="email"/>
                    <label htmlFor="fieldEmail">Enter your email</label>
                  </div>
                  <button type="submit" className="subscribe-submit">
                    <svg focusable="false" width="19" height="14">
                      <use xlinkHref={`${Icon}#icon-long-arrow`}></use>
                    </svg>
                  </button>
                </Form>     
                <Helmet>
                <script src="https://js.createsend1.com/javascript/copypastesubscribeformlogic.js"></script>
                </Helmet>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Subscribe
